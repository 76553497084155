import React from 'react'
import './Aboutsec2.css'
// import img2 from '../../img/demomap.jpg'
import img2 from '../../img/rng about.jpg'
import hoverimg from '../../img/copy of rngabout.png'
function Aboutsec2() {
    return (
        <>

            <div className="aboutsec2container">

                <div className="aboutsec2box1">
                    <div className="aboutsec2card1">
                        <h1>The Power of RNG Admart</h1>
                        <p>RNG Admart stands as a trusted and innovative business consultant, equipped with a team of seasoned professionals and industry experts. With their vast knowledge and extensive experience, It provides invaluable guidance and strategic insights to help businesses navigate challenges, optimize operations, and unlock their full potential.</p>
                    </div>
                    <div className="aboutsec2card2">
                        <img src={img2} alt="about rng admart" />
                        <div className="imgavouthover">

                        </div>
                    </div>
                </div>

                <div className="aboutsec2box2">

                    <div className="aboutsec2boxs">
                        <div className="aboutsec2boxsimg">

                            <i class="zmdi zmdi-group"></i>
                        </div>
                        <div className="aboutsec2boxscontent">

                            <h2>Collaboration</h2>
                            <p>Strategic planning fuels our success, guiding us towards growth, efficiency, and long-term prosperity.</p>
                            <div className="aboutsec2contentbtn">
                                <span><a href="/about/collaboration"><i class="fa-solid fa-check"></i> RAED MORE </a></span>
                            </div>
                        </div>
                    </div>
                    <div className="aboutsec2boxs">
                        <div className="aboutsec2boxsimg">
                            <i class="zmdi zmdi-trending-up"></i></div>
                        <div className="aboutsec2boxscontent">

                            <h2> Vision</h2>
                            <p>At RNG Admart, our vision is to be a leading force in transforming spaces, brands, and businesses. We strive to create inspiring and functional designs, establish impactful communication strategies, drive digital growth, and construct visionary projects.</p>
                            <div className="aboutsec2contentbtn">

                            </div>
                        </div>
                    </div>
                    <div className="aboutsec2boxs">
                        <div className="aboutsec2boxsimg">

                            <i class="zmdi zmdi-chart"></i></div>
                        <div className="aboutsec2boxscontent">

                            <h2>Planning</h2>
                            <p>Efficient planning, effective execution, and strategic decision-making for sustainable growth and organizational success.</p>
                            <div className="aboutsec2contentbtn">
                                <span><a href="/about/planning"><i class="fa-solid fa-check"></i> RAED MORE </a></span>
                            </div>
                        </div>
                    </div>


                </div>



            </div>

        </>
    )
}

export default Aboutsec2
