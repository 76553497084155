import React from 'react'
import './Aboutrng.css'
function Aboutrng({spam,heading,content,heading1,heading2,heading3,heading4,heading5,content1,content2,content3,content4,content5 }) {
  return (
    <>
      <div className="aboutrngcontaner">
      
      <div className="aboutrngbox">
      <h1><span>{spam}</span>{heading} </h1>
      <p>{content}</p>
      <br />
      <h6>{heading1}</h6>
      <p>{content1}</p>
      <h6>{heading2}</h6>
      <p>{content2}</p>
      <h6>{heading3}</h6>
      <p>{content3}</p>
      <h6>{heading4}</h6>
      <p>{content4}</p>
      <h6>{heading5}</h6>
      <p>{content5}</p>
      </div>
      
      </div>
    </>
  )
}

export default Aboutrng
