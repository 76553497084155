import React from 'react'

import './Slider.css'

import project1 from '../img/logo1.jpg'
import project2 from '../img/logo2dlf.jpg'
import project3 from '../img/logo3vasundhara.jpg'
import project4 from '../img/logo4.jpg'
import project5 from '../img/logo5sas.jpg'
import project6 from '../img/logo6.jpg'
import project7 from '../img/logo7.jpg'
// import project8 from '../img/logo8.jpg'
import project9 from '../img/logo9.jpg'
import project10 from '../img/logo10.jpg'
import project11 from '../img/logo11.jpg'
import project12 from '../img/logo12.jpeg'


import project15 from '../img/logo15.jpg'
import project16 from '../img/firstcry-logo.png'
import project17 from '../img/babyhug-logo.png'
import project18 from '../img/iconinfrastructure-logo.webp'
import project19 from '../img/nokia-logo.png'
import project20 from '../img/nipro-logo.png'


function Slider() {
  return (
    <>
      <div className="sliderbox">

        <div className="slider">

          <div className="slider-track">

            <div className="slide">
              <img src={project1} alt="logo" />
            </div>
            <div className="slide">
              <img src={project2} alt="logo" />
            </div>
            <div className="slide">
              <img src={project3} alt="logo" />
            </div>
            <div className="slide">
              <img src={project4} alt="logo" />
            </div>
            <div className="slide">
              <img src={project5} alt="logo" />
            </div>
            <div className="slide">
              <img src={project6} alt="logo" />
            </div>
            <div className="slide">
              <img src={project7} alt="logo" />
            </div>
            <div className="slide">
              <img src={project9} alt="logo" />
            </div>
            <div className="slide">
              <img src={project10} alt="logo" />
            </div>
            <div className="slide">
              <img src={project11} alt="logo" />
            </div>
            <div className="slide">
              <img src={project12} alt="logo" />
            </div>
            <div className="slide">
              <img src={project15} alt="logo" />
            </div>
            <div className="slide">
              <img src={project16} alt="logo" />
            </div>
            <div className="slide">
              <img src={project17} alt="logo" />
            </div>
            <div className="slide">
              <img src={project18} alt="logo" />
            </div>
            <div className="slide">
              <img src={project19} alt="logo" />
            </div>
            <div className="slide">
              <img src={project20} alt="logo" />
            </div>

            <div className="slide">
              <img src={project1} alt="logo" />
            </div>
            <div className="slide">
              <img src={project2} alt="logo" />
            </div>
            <div className="slide">
              <img src={project3} alt="logo" />
            </div>
            <div className="slide">
              <img src={project4} alt="logo" />
            </div>
            <div className="slide">
              <img src={project5} alt="logo" />
            </div>
            <div className="slide">
              <img src={project6} alt="logo" />
            </div>
            <div className="slide">
              <img src={project7} alt="logo" />
            </div>
            <div className="slide">
              <img src={project9} alt="logo" />
            </div>
            <div className="slide">
              <img src={project10} alt="logo" />
            </div>
            <div className="slide">
              <img src={project11} alt="logo" />
            </div>
            <div className="slide">
              <img src={project12} alt="logo" />
            </div>
            <div className="slide">
              <img src={project15} alt="logo" />
            </div>
            <div className="slide">
              <img src={project16} alt="logo" />
            </div>
            <div className="slide">
              <img src={project17} alt="logo" />
            </div>
            <div className="slide">
              <img src={project18} alt="logo" />
            </div>
            <div className="slide">
              <img src={project19} alt="logo" />
            </div>
            <div className="slide">
              <img src={project20} alt="logo" />
            </div>




          </div>
        </div>


      </div>


    </>
  )
}

export default Slider
