import React from 'react'
import './Branch.css'
function Branch({ heading, content, spam, para1, para2, para3, para4, para5, para6, para7, para8, para9 }) {
  return (
    <>
      <div className="branchcontainer">

        <div className="branchesbox">

          <div className="branchbox">
            <h1><span>{spam} </span>  {heading}</h1>

           
            <p>{content} <br />  {para1} <br /> <br /> {para2} <br />  <br />{para3} <br /> <br /> {para4} <br /> <br /> {para5} <br /> <br /> {para6} <br /> <br /> {para7} <br /> <br /> {para8} <br /> <br /> {para9} </p>

          </div>


        </div>

      </div>
    </>
  )
}

export default Branch
