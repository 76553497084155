import React from 'react'
import './Historysec2.css'
import img1  from '../img/history photo.jpg'

function Historysec2() {
  return (
    <>
      <div className="historysec2">
      
      <div className="historysec2box">
      
      <div className="historysecbox1">
      
      <h6>MORE DETAILS</h6>
<br />
     
      <p> <strong>The Evolution of RNG Admart: </strong>  From Interior Design to a Multifaceted Company  RNG Admart, a prominent company in the business landscape, began its journey in May 2011 as a
      humble interior design firm. Over the years, it expanded its services and transformed into a multifaceted
      enterprise, incorporating public relations (PR) Service Provider, digital marketing, and construction divisions. This
      article delves into the remarkable evolution of RNG Admart and how it successfully diversified its
      portfolio to become a leading player in multiple industries.</p>
      <br />

      <div className="showhidecontent1">
      
      <p><strong>Founding and Early Years:</strong></p>
      <p> RNG Admart was founded in May 2011 by a group of passionate individuals with expertise in interior
      design. The company aimed to offer innovative and personalized design solutions to clients seeking to
      transform their living and working spaces. With a commitment to quality and creativity, RNG Admart
      quickly gained recognition for its exceptional interior design services, earning a loyal customer base. </p>
     
      </div>
      
      </div>
     
      <div className="historysec2box2">
      <img src={img1} alt="History of rng admart" />
      
      </div>
      
      </div>

      <div className="history2content">

      <div className="showhidecontent2">
      
      <h5>Founding and Early Years:</h5>
      <p> RNG Admart was founded in May 2011 by a group of passionate individuals with expertise in interior
      design. The company aimed to offer innovative and personalized design solutions to clients seeking to
      transform their living and working spaces. With a commitment to quality and creativity, RNG Admart
      quickly gained recognition for its exceptional interior design services, earning a loyal customer base. </p>
      
      </div>
     
      <h5>Expansion into PR Service Provider:</h5>
      <p> Building upon its initial success, RNG Admart recognized the importance of effective communication and
      brand positioning. In the pursuit of enhancing its offerings, the company ventured into the realm of
      public relations. By establishing a PR division, RNG Admart began assisting clients in developing
      comprehensive communication strategies, managing their public image, and leveraging media relations
      to foster positive brand awareness. This expansion allowed RNG Admart to cater to a broader range of
      business needs and further solidify its reputation as a reliable service provider. </p>
      
      <h5>Diversification into Digital Marketing:</h5>
      <p> As technology continued to revolutionize the business landscape, RNG Admart acknowledged the
      growing significance of digital marketing. In response to evolving market demands, the company
      expanded its services to include digital marketing solutions. By harnessing the power of social media,
      search engine optimization (SEO), content marketing, and online advertising, RNG Admart enabled its
      clients to reach wider audiences, increase brand visibility, and generate measurable results in the digital
      realm. This diversification positioned RNG Admart as a one-stop destination for comprehensive
      marketing solutions. </p>
      
      <h5>Venturing into Construction:</h5>
      <p> In a strategic move to capitalize on the booming construction industry, RNG Admart extended its
      expertise to encompass construction services. Leveraging its experience in interior design and
      understanding of architectural concepts, the company began offering construction project management,
      turnkey solutions, and architectural design services. This expansion allowed RNG Admart to undertake
      end-to-end construction projects, from conceptualization to execution, catering to both residential and
      commercial clients. </p>

      
      <h5>Achievements and Future Outlook:</h5>
      <p>Through its strategic expansions and commitment to excellence, RNG Admart has achieved significant
      milestones since its inception. The company has successfully completed numerous high-profile projects,
      earning recognition for its design aesthetics, attention to detail, and professionalism. RNG Admart`s
      ability to adapt and diversify has allowed it to stay at the forefront of the industry, continually meeting
      the evolving needs of its clients. <br />
      <br />
      Looking ahead, RNG Admart aims to continue its growth trajectory by embracing emerging technologies,
      fostering innovation, and maintaining its commitment to client satisfaction. With a strong foundation
      built on interior design, PR, digital marketing, and construction, RNG Admart is poised to further expand
      its service offerings and strengthen its position as a leading player in the multifaceted business
      landscape. <br /> <br />
      From its origins as an interior design firm in 2011, RNG Admart has grown into a versatile company
      offering a range of services including PR Service Provider, digital marketing, and construction. Its ability to adapt
      and diversify has allowed the company to stay relevant and competitive in an ever-changing business
      environment. RNG Admart`s success story serves as an inspiration for aspiring entrepreneurs and
      showcases the power of strategic expansion and innovation in achieving long-term growth and success.</p>





      </div>
      
      
      </div>
    </>
  )
}

export default Historysec2
