import React from 'react'
import Aboutsec1 from './about/aboutsec1/Aboutsec1'
import Cardslider from './cardslider/Cardslider'
import Piccontent from './piccontent/Piccontent'
import RuningChangeText from './runningchnagetext/RuningChangeText'
import { Helmet } from 'react-helmet'

function OurServices() {
  return (
    <div>

    <Helmet>
    <title>Empowering Solutions: RNG's Comprehensive Services in Interior, Construction, Digital Marketing, and
    PR</title>
    <meta
      name="description"
      content="Unleashing potential: RNG's services in interior design, construction, digital marketing, and PR redefine
      excellence. Experience our comprehensive solutions today."
      
    />
    <link rel="canonical" href="/services" />

    </Helmet>

      <Aboutsec1 title = "Our Services" content ="As the mother company of Dishank Realtors and Social 2 Vocal, RNG offers a comprehensive range of services that cater to various industries. With our expertise in interior design, construction, digital marketing, and more, we provide holistic solutions to meet your specific needs. As a complete PR Service Provider, we are committed to delivering excellence in every project we undertake." />
      <Cardslider/>
      <RuningChangeText/>
    </div>
  )
}

export default OurServices
