import React from 'react'
import './Contact.css'

function Contactsec1() {
  return (
    <>

      <div className="contactsec1container">

        <div className="contactsec1boxs">

          <div className="contactsec1box">
            <i class="zmdi zmdi-timer"></i>

            <h3>Open Hours</h3>
            <p>Mon-Fri: 10 AM – 7 PM <br />
              Saturday: 10 AM – 7 PM <br />
              Sunday: Closed</p>

          </div>
          <div className="contactsec1box">

            <i class="zmdi zmdi-pin-drop"></i>

            <h3>Address </h3>
            <p> DLF My Pad, B2-1009, Vijaipur Colony, Vibhuti<br /> Khand, Gomti Nagar,  Lucknow, Uttar Pradesh 226010</p>


          </div>
          <div className="contactsec1box">

            <i class="zmdi zmdi-headset-mic"></i>
            <h3>Get In Touch</h3>
            <p> <span> Telephone:</span>    <a jsname="zGowAe" class="rABsFf RffVmb AL18ce" data-value="+919935358405" href="tel:+919935358405" aria-label="+919935358405 From user's Google profile" data-tooltip="From user's Google profile" data-tooltip-position="top" jslog="42420; track:click,impression">+919935358405</a>     <br />
             <span>  Email:</span> <a jsname="YheIzf" class="rABsFf RffVmb AL18ce" target="_blank" jslog="35051; track:click,impression" href="mailto:rngadmart@gmail.com" aria-label="rngadmart@gmail.com From user's Google profile" data-tooltip="From user's Google profile" data-tooltip-position="top">rngadmart@gmail.com</a>
            </p>
          </div>
        </div>


      </div>

      <div className="contactsec2container">

        <div className="contactsec2boxs">

          <div className="contactsec2box1">

          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14236.772474039282!2d81.0060734!3d26.8656048!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be3b5e544e8cf%3A0xc5b9b045d844e6e5!2sTech%20Nexus%20%7C%20Best%20Digital%20Marketing%20Agency%20in%20Lucknow!5e0!3m2!1sen!2sin!4v1688454242324!5m2!1sen!2sin" width="100%" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          
      </div>

          <div className="contactsec2box2">

<form >

<div className="forminputs">
<input type="text" placeholder='First name' />
<input type="text" placeholder='Last name'/>
</div>

<div className="forminputs">
<input type="text" placeholder='Email' />
<input type="text" placeholder='Phone' />
</div>

<div className="forminputs">

<textarea name="message" id="" cols="30" rows="2" placeholder='Message'></textarea>
</div>


<div className="contactbutton">
<button>GET IN TOUCH</button>
</div>

</form>
          </div>


        </div>



      </div>

    </>
  )
}

export default Contactsec1
