import React from 'react'
import './Navbar.css'
// import logo from '../img/rng admart.png'
import logo from '../img/rng admart1.png'
import { NavLink } from 'react-router-dom'
function Navbar({ openSidebar }) {
  return (
    <>
      <div className="navcontainer">

        <div className="navboxs">
          <div className="navbox1">
            <img src={logo} alt="rng logo" />
          </div>
          <div className="navbox2">
            <ul>
              <li> <NavLink to="/">Home</NavLink></li>
              <li id='aboutlink' > <NavLink to="#">About Us <i class="zmdi zmdi-chevron-down"></i></NavLink>
                <div className="aboutuslink">
                  <ul>
                    <li><NavLink to="/overview">Overview </NavLink> </li>
                    <li><NavLink to="/history">History </NavLink> </li>
                    <li><NavLink to="/teammember">Team Members </NavLink> </li>
                    <li><NavLink to="/testimonials">Clients & Testimonials </NavLink> </li>
                  </ul>
                </div>
              </li>
              <li> <NavLink to="/services">Our Services</NavLink></li>
              <li id='projectlink' > <NavLink to="/projects">Gallery  </NavLink>
              
              
              
              </li>
              <li id='projectlink' > <NavLink to="#">Branches <i class="zmdi zmdi-chevron-down"></i>  </NavLink>
              <div className="projectslink">
              <ul>
               
                <li><NavLink to="/Dishank-Realtors"> Dishank Realtors </NavLink> </li>
                <li><NavLink to="/Social2Vocal"> Social2Vocal </NavLink> </li>
              </ul>
            </div>

              </li>
              <li> <NavLink to="/contact">Contact Us</NavLink></li>
            </ul>
          </div>
          <div className="navbox3">
            <a target='_blank' href="https://www.facebook.com/profile.php?id=100068313607481&mibextid=ZbWKwL"> <i class="fa-brands fa-square-facebook linki"></i></a> 
            <i class="fa-brands fa-square-instagram linki"></i>
            <i class="fa-brands fa-square-twitter linki"></i>
            <i class="fa-brands fa-square-youtube linki"></i>

          </div>
          <div onClick={openSidebar} className="navbox4">
            <i class="fa-solid fa-bars navmenu"></i>
          </div>


        </div>


      </div>
    </>
  )
}

export default Navbar
