import React, { useState } from 'react'
import '../runningchnagetext/Runingtext.css'
import Typewriter from 'typewriter-effect';


function RuningChangeText() {

    const [state] = useState({
        title: "Hi",
        titleTwo: "I`m",
        titleThree: "rahul"
    })

    return (
        <>

            <div className="runingtextcontainer">
                <div className="runningtextheading">
                    <p>WHY WE US</p>
                </div>

                <div className="runningtextmid">
                    <h3>Where Every Detail Counts..  <br /> Our  goal is  </h3>
                    <span> <Typewriter
                    options={{
                      strings: ['success.', ' Stability .','growth.','Strength.'],
                      autoStart: true,
                      loop: true,
                    }}
                  /> </span>







                   
                </div>


            </div>

        </>
    )
}

export default RuningChangeText
