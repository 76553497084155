import React, { useState } from 'react'


import './Project.css'

function Project({ data}) {

  const [model, setmodel] = useState(false);
  const [tempimgsrc, setTempImgSrc] = useState('');


  const getImg = (imgSrc) => {

    setTempImgSrc(imgSrc);
    setmodel(true);

}
  return (
    <>


    
    <div className={model ? "model open" : "model"}>

    <div className="modelimgcontainer">
        <img src={tempimgsrc} alt="" />

        <h1 onClick={() => setmodel(false)} ><i class="zmdi zmdi-close"></i></h1>
    </div>




</div>





      <div className="projectcontainer">
        <div className="projectboxs">
          <div className="data">
            <div className="gallarycontainer1">
              <div className="gallarybox12">
                {data.map((item, index) => {
                  return (
                    <div className="piccontainer" key={index} onClick={() => getImg(item.imgSrc)}>
                      <img src={item.imgSrc} alt="" />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Project
