import React from 'react'
import Branch from './branches/Branch'
import Aboutsec1 from './about/aboutsec1/Aboutsec1'

function Branch1() {


  let heading = "Dishank Realtors - Building Dreams into Reality"
  
  let para1 = `There are several compelling reasons why we choose to become a construction company. Here are a
  few key factors that often drive this decision:`
  
  let para2 = `Building Infrastructure: We play a crucial role in building the physical infrastructure
  that shapes our communities. By becoming a construction company, individuals can contribute to the
  development of residential, commercial, and public structures, such as homes, offices, schools,
  hospitals, bridges, and roads. This provides a sense of accomplishment and pride in creating tangible
  assets that serve the needs of society.`
  
  let para3 = `Meeting Growing Demand: As populations continue to grow, there is an increasing need for
  construction services. By becoming a construction company, individuals can tap into this demand and
  fulfill the need for new construction, renovation, and expansion projects. This creates a steady stream of
  opportunities and potential for growth in the industry.`
  
  let para4 = `Collaboration and Teamwork: Construction projects require collaboration and teamwork from various
  professionals, including architects, engineers, contractors, and skilled laborers. By establishing a
  construction company, individuals can create a platform for bringing together diverse talents and
  expertise, fostering a collaborative environment where each member contributes to the successful
  completion of projects.`

  let para5 = `Job Creation and Economic Impact: The construction industry is a significant contributor to job creation
  and economic growth. By starting a construction company, individuals can generate employment
  opportunities for skilled and unskilled workers, providing them with stable income and contributing to
  the local economy. Additionally, construction projects often have a multiplier effect on related
  industries, stimulating economic activity in sectors such as manufacturing, logistics, and services.`
  
  let para6 = `Problem Solving and Innovation: Construction projects present unique challenges and require problem-
  solving skills. By becoming a construction company, individuals can engage in innovative solutions and
  technologies to improve efficiency, safety, and sustainability in construction processes. This fosters
  continuous learning and improvement, making the construction industry an exciting and dynamic field.`
  
  let para7 = `Long-Term Relationships: Construction projects often involve long-term relationships with clients,
  suppliers, subcontractors, and other stakeholders. By establishing a construction company, individuals
  have the opportunity to build and nurture these relationships, developing a reputation for delivering
  quality work, meeting deadlines, and providing exceptional customer service. These relationships can
  lead to repeat business and referrals, fostering the growth and sustainability of the company.`
  
  let para8 = `Physical Legacy: Construction companies have the potential to leave a lasting physical legacy in the form
  of iconic buildings and structures. By embarking on construction projects, individuals can create
  
  structures that become landmarks, symbols of progress, and sources of inspiration for generations to
  come.`

  let para9 = `Overall, becoming a construction company offers individuals the chance to contribute to society by
  building essential infrastructure, creating jobs, and driving economic growth. It provides opportunities
  for collaboration, problem-solving, and innovation, making it a rewarding and impactful industry to be a
  part of.`
  
  let spam = ""
  return (
    <>
      <Aboutsec1 title='Construction' content="In today's competitive business landscape, it is crucial to have a comprehensive solution that caters to multiple aspects of your enterprise. RNG offer just that, with two distinct branches: Social2Vocal for digital marketing and PR agency services, and Dishank Realtors for construction, real estate, and interior designing." />
      <Branch spam={spam} heading={heading}  para1 = {para1} para2 = {para2} para3 = {para3} para4 = {para4} para5 = {para5} para6 = {para6} para7 = {para7} para8 = {para8} para9 = {para9} />
    </>
  )
}

export default Branch1
