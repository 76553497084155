import React from 'react'
import './Homemain.css'

function Homemain() {
  return (
    <>
    <div className="homemaincontainer">
    
    <div className="homemainheading">
    
    
    </div>

    
    </div>
      
    </>
  )
}

export default Homemain
