import React from 'react'
import Aboutsec1 from './about/aboutsec1/Aboutsec1'
import Aboutrng from './about/aboutrng/Aboutrng'

function Aboutrng3() {


  let content  = `RNG Admart, a company that began its journey in May 2011 as an interior design firm, has successfully
  expanded its services to encompass PR agency, digital marketing, and construction divisions. This
  planning content outlines the strategic steps taken by RNG Admart to diversify its offerings and become
  a multifaceted enterprise, highlighting the key considerations and milestones achieved along the way.`

  let heading1 = `Identifying Market Trends and Opportunities:`
  let content1  = `In the early stages, RNG Admart recognized the changing dynamics of the market and identified
  emerging trends in the interior design industry. By conducting thorough market research, analyzing
  customer demands, and staying attuned to industry shifts, the company proactively identified
  opportunities to expand its services into new domains.`

  let heading2 = `Building a Strong Foundation in Interior Design:`
  let content2 = `RNG Admart established a strong reputation in interior design by delivering exceptional results and
  building a loyal customer base. This served as a foundation for future expansion, ensuring a solid track
  record and client satisfaction as the company ventured into new areas.`

  let heading3 = `Strategic Partnerships and Collaborations:`
  let content3 = `To facilitate its expansion, RNG Admart sought strategic partnerships and collaborations with industry
  experts and professionals in the respective fields. These collaborations helped the company leverage
  additional expertise, expand its knowledge base, and access new networks, ultimately enhancing the
  quality and range of services offered.`

  let heading4 = `Gradual Expansion into PR Agency Services:`
  let content4 = `Recognizing the importance of effective communication and brand management, RNG Admart
  strategically expanded into the PR agency domain. By leveraging its existing clientele and relationships,
  the company seamlessly integrated PR services into its portfolio, offering comprehensive solutions to
  clients seeking to enhance their public image and brand positioning.`


  return (
    <>
  
    <Aboutsec1 title='Planning' content = 'With a strategic planning approach, RNG Admart began its journey in May 2011 as an interior design firm and expanded into PR agency services. Gradually, the company ventured into digital marketing and construction as well, aligning its growth with market trends and customer demands. This meticulous planning enabled RNG Admart to diversify its offerings, cater to a wider range of client needs, and establish itself as a multifaceted enterprise in the industry.'/>
      
    <Aboutrng  content = {content} heading1= {heading1} content1 = {content1} heading2= {heading2} content2 = {content2} heading3 = {heading3} content3 = {content3}  heading4 = {heading4} content4 = {content4} />
      
    </>
  )
}

export default Aboutrng3
