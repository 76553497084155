import React from 'react'
import Aboutsec1 from './about/aboutsec1/Aboutsec1'

function Privacy() {
  return (
    <>

   


<div className="privacycontainer">

<h2>Terms and Conditions</h2>

<p> Thank you for considering RNG, the mother company of Dhishnk Real-Estate Company and S2V Digital
Company. Please take a moment to review our terms and conditions:</p>
<p>1. Services: RNG offers a range of services including interior design, construction, digital marketing,
and PR. The specific services provided will be outlined in a mutually agreed-upon contract or agreement.
<br />
2. Pricing and Payments: Pricing for our services will be discussed and agreed upon before the
commencement of any project. Payments will be made according to the terms outlined in the contract,
which may include upfront deposits and milestone-based payments.
<br />
3. Intellectual Property: Any intellectual property created or provided by RNG, such as designs,
marketing materials, or branding strategies, shall remain the property of RNG unless otherwise agreed
upon in writing.
<br />
4. Confidentiality: RNG respects the confidentiality of client information and will handle all
sensitive data with utmost care. We will not disclose any confidential information to third parties
without the client's consent, unless required by law.
<br />
5. Termination: Either party may terminate the engagement with written notice, outlining the
reasons for termination. Termination terms, including any applicable fees or refund policies, will be
outlined in the contract.

<br />
<br />
Please note that these terms and conditions are subject to negotiation and may vary based on the
specific project or agreement. We encourage open communication and are committed to finding
mutually beneficial solutions for our clients.
If you have any questions or require further clarification, please do not hesitate to contact us.
</p>

<br />
<br />

<h2> Privacy Policy</h2>
<p> At RNG, the mother company of Dhishnk Real-Estate Company and S2V Digital Company, we are
committed to protecting the privacy and personal information of our clients and website visitors. Please
take a moment to review our privacy policy: <br /> <br />
1. Data Collection: We may collect personal information such as names, contact details, and
project requirements when you engage with us. This information is collected with your consent and is
used solely for the purpose of providing our services and improving your experience.
<br />
2. Data Storage and Security: We employ industry-standard measures to ensure the security and
confidentiality of your personal information. This includes secure data storage, restricted access, and the
use of encryption technology.
<br />
3. Third-Party Disclosure: We may share your personal information with third-party service
providers who assist us in delivering our services. However, we only disclose the necessary information
required for them to fulfill their specific tasks and we ensure they adhere to strict privacy standards.
<br />
4. Website Analytics: We may use website analytics tools to collect non-personal information such
as website usage data, page views, and browsing patterns. This information helps us improve our
website and deliver a better user experience.
<br />
5. Cookies: Our website may use cookies, which are small files stored on your device, to enhance
your browsing experience. You have the option to accept or decline cookies through your browser
settings.
<br />
6. Communication: We may occasionally send you promotional emails or updates about our
services if you have provided your consent. You have the right to opt out of such communications at any
time.
<br />
<br />
Please note that our privacy policy may be updated periodically, and any changes will be reflected on
our website. By engaging with RNG, Dhishnk Real-Estate Company, and S2V Digital Company, you
consetn to the terms outlined in our privacy policy.
<br />
If you have any questions or concerns regarding our privacy practices, please contact us for further
assistance.
</p>

</div>

      
    </>
  )
}

export default Privacy
