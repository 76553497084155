import React from 'react'
import './Branch.css'



function Branch4({projectcontent, branchimg1,branchimg2,branchimg3,branchimg4,branchimg5,logo, spam, heading, para1, para2, content, facebooklink, instalink }) {
  return (
    <>

      <div className="branchcontainer">

        <div className="branchesbox">

          <div className="branchbox">
            <h1><span>{spam} </span>  {heading}</h1>

            <div className="branchimg">
              <div className="img">
                <img src={logo} alt="" />
                <div className="sociallinks">

                <a href={facebooklink} target='_blank' > <i class="fa-brands fa-facebook-f ilink"></i></a>
                <a href={instalink} target='_blank' > <i class="fa-brands fa-instagram ilink"></i></a>
              </div>

              </div>
              <div className="img1">
              <p>{para1} </p>
              </div>
            </div>

           

          </div>




          <div className="branchproject">
        <h2>Our Projects</h2>
        <p> {projectcontent}</p>

        <div className="branchprojectimg">
        
        <div className="branchimgs">
        <img src={branchimg1} alt="" />
        </div>
        <div className="branchimgs">
        <img src={branchimg2} alt="" />
        </div>
        <div className="branchimgs">
        <img src={branchimg3} alt="" />
        </div>
        <div className="branchimgs">
        <img src={branchimg4} alt="" />
        </div>
        <div className="branchimgs">
        <img src={branchimg5} alt="" />
        </div>

        </div>
          
          </div>
        
          

        


        </div>
      

      </div>
      <div className="branchpart2">
      <p>{content}   {para2} <br />   </p>
      </div>

    </>
  )
}

export default Branch4
