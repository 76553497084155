import React from 'react'
import Aboutsec1 from './about/aboutsec1/Aboutsec1'
import Aboutrng from './about/aboutrng/Aboutrng'

function Aboutrng1() {
    let spam = `A Collaboration of Expertise:`
    let heading = `RNG Admart&#39;s Journey from
    Interior Design to a Multifaceted Enterprise`

    let content  = `RNG Admart, a dynamic company established in May 2011, embarked on a remarkable journey that saw
    it evolve from an interior design firm into a multifaceted enterprise. With its expertise expanding to
    include PR agency, digital marketing, and construction divisions, RNG Admart has forged successful
    collaborations across industries, offering clients a comprehensive range of services under one roof. This
    article explores the collaborative prowess of RNG Admart and highlights the benefits of its diverse
    capabilities.`

    let heading1 = `Interior Design: Where It All Began:`
    let content1  = `Recognizing the importance of effective communication, RNG Admart expanded into the realm of public
    relations. Collaborating with businesses seeking to enhance their brand image and outreach, the
    company leveraged its expertise to develop comprehensive communication strategies. Through
    collaboration with clients, RNG Admart crafted compelling narratives, managed media relations, and
    facilitated targeted campaigns to ensure maximum brand exposure and engagement.`

    let heading2 = `Digital Marketing: Navigating the Digital Landscape:`
    let content2 = `As the digital era gained prominence, RNG Admart foresaw the need to provide clients with innovative
    marketing solutions. Through collaboration with digital strategists and marketing experts, the company
    diversified into digital marketing services. RNG Admart's collaborative approach allowed it to navigate
    the ever-evolving digital landscape, leveraging techniques such as social media marketing, content
    creation, and search engine optimization to drive online visibility, customer engagement, and business
    growth.`
    let heading3 = `Construction: Realizing Visionary Projects:`
    let content3 = `With a solid foundation in design and a penchant for collaboration, RNG Admart ventured into the
    construction industry. By partnering with architects, engineers, and contractors, the company offered
    turnkey solutions, project management, and architectural design services. Collaborating with clients,
    RNG Admart brought visionary projects to life, merging functionality with innovative design concepts to
    create spaces that inspire and endure.`
    let heading4 = `Benefits of Collaborative Expertise:`
    let content4 = `RNG Admart's journey from interior design to a multifaceted enterprise reflects the power of
    collaboration. By integrating diverse capabilities, the company offers clients a holistic approach to their
    unique needs. Collaborative partnerships enable RNG Admart to tap into a network of industry experts,
    fostering creativity, innovation, and efficient project execution. The result is comprehensive solutions
    that harmonize design aesthetics, communication strategies, digital marketing campaigns, and
    construction endeavors, all tailored to exceed client expectations.`
  return (
    <div>
    <Aboutsec1 title='Collaboration' content = 'Proactively envisioned multimedia based expertise and cross-media growth strategies. Seamlessly visualize quality intellectual capital without superior collaboration and idea-sharing.'/>
  
    <Aboutrng   content = {content} heading1= {heading1} content1 = {content1} heading2= {heading2} content2 = {content2} heading3 = {heading3} content3 = {content3}  heading4 = {heading4} content4 = {content4} />
    </div>
  )
}

export default Aboutrng1
