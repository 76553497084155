import React from 'react'
import Aboutsec1 from './about/aboutsec1/Aboutsec1'
import Branch from './branches/Branch'
import Branch4 from './branches/Branch4'
import logo from './img/social2vocal-logo.jpg'
import branchimg1 from './img/branchimg/NISHUWELFARELOGO.jpg'
import branchimg2 from './img/branchimg/MAA GAYATRI LOGO.jpg'
import branchimg3 from './img/branchimg/Lucknow MahotsaVLOGO.jpg'
import { Helmet } from 'react-helmet'

function Services2() {

  let heading = " - Empowering Your Digital Presence"

  let para1 = `In the era of digitalization, having a strong online presence is paramount for business success. Social2Vocal, a branch of RNG, specializes in providing cutting-edge digital marketing and PR agency solutions. By leveraging the power of social media platforms, content creation, search engine optimization, and strategic branding, Social2Vocal helps businesses reach their target audience, enhance brand visibility, and drive growth. With a team of skilled professionals and a deep understanding of market dynamics, Social2Vocal ensures that your digital marketing efforts are impactful, result-driven, and tailored to meet your specific business objectives.`
  let para2 = `RNG not only provide the precision and randomness required in mathematical and computer science applications but have also diversified into the realm of business solutions. Through its branches, Social2Vocal and Dishank Realtors, RNG offers a complete package for digital marketing, PR services, construction, real estate, and interior designing. Embrace the power of RNG's expertise and watch your business thrive in the digital and physical worlds.`

 
  let spam = "Social2Vocal"

  let facebooklink = 'https://www.facebook.com/profile.php?id=100092388888178&mibextid=ZbWKwL'
  let instalink = 'https://www.instagram.com/social2vocal/?igshid=Y2IzZGU1MTFhOQ%3D%3D'

  let projectcontent = 'At Social 2 Vocal, our projects are more than just initiatives; they represent our commitment to harnessing the potential of social media for good. With every endeavor, we embrace the responsibility of driving positive change, embracing diversity, and fostering an inclusive digital space that empowers individuals and uplifts communities.'


  return (
    <>
    <Helmet>
    <title> Social2Vocal for digital marketing and PR services </title>
    
    <meta
      name="description"
      content="At Social 2 Vocal, our projects are more than just initiatives; they represent our commitment to harnessing the potential of social media for good."
      
    />
    <link rel="canonical" href="/Social2Vocal" />

    </Helmet>



    <Aboutsec1 title='Branches' content="In today's competitive business landscape, it is crucial to have a comprehensive solution that caters to multiple aspects of your enterprise. RNG offer just that, with two distinct branches: Social2Vocal for digital marketing and PR services, and Dishank Realtors for construction, real estate, and interior designing." />
 

    <Branch4 branchimg1 = {branchimg1} branchimg2 = {branchimg2} branchimg3 = {branchimg3} projectcontent = {projectcontent} logo = {logo} spam={spam} heading={heading} para1={para1} para2={para2} facebooklink = {facebooklink} instalink = {instalink} />
    




    </>
  )
}

export default Services2
