import React from 'react'
import Aboutsec1 from './about/aboutsec1/Aboutsec1'
import Contactsec1 from './contact/Contactsec1'
import { Helmet } from 'react-helmet'

function Contact() {
  return (
    <>

    <Helmet>
    <title>Contact RNG for Interior, Construction, Digital Marketing, and PR Solutions </title>
    <meta
      name="description"
      content="Connect with RNG: Interior, Construction, Digital Marketing, and PR solutions at your fingertips. Contact
      us now to discuss your project and see how we can help you excel."
      
    />
    <link rel="canonical" href="/contact" />

    </Helmet>


      <Aboutsec1 title ="Contact Us" content = "Thank you for your interest in RNG Admart, RNG Admart is the mother company of Dhishank Realtors  and Social 2 Vocal. We are specialized in interior design, construction, digital marketing, and PR. If you have any inquiries or would like to discuss how we can assist you with your needs, please feel free to reach out to us. Our dedicated team is here to provide you with the best solutions tailored to your requirements. "/>
      <Contactsec1/>
    </>
  )
}

export default Contact
