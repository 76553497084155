import React from 'react'
import './Aboutsec3.css'
import { NavLink } from 'react-router-dom'

function Aboutsec3() {
    return (
        <>

            <div className="aboutsec3container">
                <div className="aboutsec3boxs">
                    <h2>Our Expertize</h2>
                    <p>At RNG Admart, their commitment to excellence is reflected in the wide array of consulting services they offer. By understanding the unique requirements and goals of each client, RNG Admart delivers tailored solutions to address critical areas of business development, strategy, and growth.</p>
                </div>
                <div className="aboutsec3boxs">
                    <div className="aboutsec3box2">
                        <div className="aboutsec3box21">
                            <div className="aboutsec3box21img">
                            <i class="fa-solid fa-lightbulb ilogo"></i>
                            </div>
                            <div className="aboutsec3box21content">
                                <h3>Business Strategy</h3>
                                <p>RNG Admart works closely with organizations to develop robust business strategies that align with their vision and objectives. Through in-depth market research, competitive analysis, and strategic planning, RNG Admart helps clients identify opportunities, mitigate risks, and create a roadmap for success.</p>
                            </div>
                        </div>
                        <div className="aboutsec3box21">
                            <div className="aboutsec3box21img">
                            <i class="fa-solid fa-chart-pie ilogo"></i>
                           
                            </div>
                            <div className="aboutsec3box21content">
                                <h3>Consultation</h3>
                                <p>RNG Admart offers comprehensive  consulting services, including  analysis, forecasting, budgeting, and risk management. Their team of  experts assists clients in making informed decisions,  and maximizing profitability.</p>
                            </div>
                        </div>
                        <div className="aboutsec3box21">
                            <div className="aboutsec3box21img">
                            <i class="fa-solid fa-shop ilogo"></i>
                            </div>
                            <div className="aboutsec3box21content">
                                <h3>Marketing and Branding</h3>
                                <p>With the ever-evolving digital landscape, RNG Admart understands the importance of effective marketing and branding strategies. They help businesses develop impactful marketing campaigns, leverage digital channels, build strong brand identities, and engage with their target audience to drive awareness and growth.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="aboutsec3boxs">
                  <div className="aboutsec3button">
                  
                  <button><a href="/services">LEARN MORE</a> </button>
                  </div>
                </div>
            </div>
        </>
    )
}

export default Aboutsec3
