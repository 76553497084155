import React from 'react'
import Aboutsec1 from './about/aboutsec1/Aboutsec1'
import Historysec1 from './historysec1/Historysec1'
import Historysec2 from './Historysec2/Historysec2'
import Historysec3 from './historysec3/Historysec3'
import { Helmet } from 'react-helmet'

function History() {
  return (
    <>

    <Helmet>
    <title>A Legacy of Innovation: RNG's Journey in Interior, Construction, Digital Marketing, and PR</title>
    <meta
      name="description"
      content="Pioneering progress: RNG's rich history as a mother company to Dhishnk Real-Estate Company and S2V
      Digital Company, driving excellence in interior design, construction, digital marketing, and PR."
      
    />
    <link rel="canonical" href="/history" />

    </Helmet>


    
    <Aboutsec1 title =" History" content ="With a legacy rooted in excellence, RNG Admart has emerged as a leading force in the industry. As the mother company of Dishank Realtors and Social 2 Vocal, we have a diverse portfolio spanning interior design, construction, digital marketing, and more. Since our establishment, we have been reshaping the PR landscape with our complete agency approach. From humble beginnings to industry pioneers, RNG Admart continues to redefine the standards of success, one exceptional project at a time." />


<Historysec1/>
<Historysec2/>



    </>
  )
}

export default History
