import React from 'react'

function Refund() {
  return (
    <>

    

<div className="privacycontainer">

<h2>Refund Policy</h2>

<p>At RNG, the mother company of Dhishnk Real-Estate Company and S2V Digital Company, we strive to
ensure client satisfaction. Please review our refund policy:</p>
<p>1. Deposit and Retainers: For projects that require upfront deposits or retainers, these payments
are non-refundable unless otherwise specified in the contract or agreement.
<br />
2. Milestone-Based Payments: In projects with milestone-based payment structures, refunds may
be applicable for milestones that have not been reached or completed. Refund eligibility will be
assessed on a case-by-case basis.
<br />
3. Change or Cancellation: If there is a need to change or cancel a project, refunds for work not yet
performed will be evaluated based on the stage of the project and the resources already allocated. Any
costs incurred up to the point of change or cancellation may be deducted from the refund amount.
<br />
4. Fair Assessment: Refund requests will be assessed fairly, taking into account the circumstances,
project progress, and any contractual agreements. RNG reserves the right to determine refund amounts
based on these factors.
<br />
5. Non-Refundable Services: Certain services, such as consultations, assessments, or customized
deliverables, may be considered non-refundable due to the nature of the work involved. This will be
communicated and agreed upon before the initiation of such services.

<br />
<br />
We understand that each project is unique, and our refund policy is designed to provide fair solutions in
accordance with the circumstances. If you have any questions or concerns regarding our refund policy,
please contact us for further clarification.
<br />
Please note that this refund policy is subject to the terms and conditions outlined in the contract or
agreement between RNG and the client.
</p>

<br />
<br />


</div>

      
    </>
  )
}

export default Refund
