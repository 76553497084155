import React from 'react'
import './Historysec1.css'

function Historysec1() {
    return (
        <>

            <div className="historycontaner">
            
                <div className="histrorybox1">
                    <h6>Achievements and Future Outlook</h6>
                   
                </div>
                <div className="historybox2">

                    <div className="historybox">
                        
                        <div className="historyboxcontent">
                           
                            <p>Through its strategic expansions and commitment to excellence, RNG Admart has achieved significant
                            milestones since its inception. The company has successfully completed numerous high-profile projects,
                            earning recognition for its design aesthetics, attention to detail, and professionalism. RNG Admart&#39;s
                            ability to adapt and diversify has allowed it to stay at the forefront of the industry, continually meeting
                            the evolving needs of its clients.
                            </p>

                        </div>


                    </div>
                    <div className="historybox">
                      
                        <div className="historyboxcontent">
                            
                            <p>Looking ahead, RNG Admart aims to continue its growth trajectory by embracing emerging technologies,
                            fostering innovation, and maintaining its commitment to client satisfaction. With a strong foundation
                            built on interior design, PR, digital marketing, and construction, RNG Admart is poised to further expand
                            its service offerings and strengthen its position as a leading player in the multifaceted business
                            landscape.
                            </p>

                        </div>


                    </div>
                    <div className="historybox">
                       
                        <div className="historyboxcontent">
                           
                            <p>From its origins as an interior design firm in 2011, RNG Admart has grown into a versatile company
                            offering a range of services including PR agency, digital marketing, and construction. Its ability to adapt
                            and diversify has allowed the company to stay relevant and competitive in an ever-changing business
                            environment. RNG Admart&#39;s success story serves as an inspiration for aspiring entrepreneurs and
                            showcases the power of strategic expansion and innovation in achieving long-term growth and success.
                            </p>

                        </div>


                    </div>


                </div>

            </div>

        </>
    )
}

export default Historysec1
