import React from 'react'
import './Cardslider.css';



// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y,  } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function Cardslider() {



let size = window.outerWidth;
console.log(size);
let number;
if(size>1000 ){
     number = 3;
}
else if(size>=700 && 1000>size){
     number = 2;
}
else{
     number = 1;
}


    


    return (
        <>
            <div className="cardslidercontainer" id='cardsliderwidth'>
                <div className="slideruper">
                   
                    <h3>Your results are our top <span>  priority!</span></h3>
                </div>

                
                
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, Scrollbar, A11y,]}
                    spaceBetween={1}
                    slidesPerView={number}
                    navigation
                    pagination={{ clickable: true }}
      
                    

                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                >

                <div className="cardcontaners">
                    <SwiperSlide>
                        <div className="cardcontaner">
                            <div className="cardbox">
                                <div className="cardboximg">
                                    <div className="cardimgbox">
                                        <i class="fa-solid fa-lightbulb cardimgboxi"></i>
                                    </div>
                                </div>
                                <h3> PR Sevices </h3>
                                <p>RNG is more than just a PR Sevice Provider it is a dedicated partner committed to your
                                organization&#39;s success. With their wide range of services ...</p>
                                <div className="cardboxbutton">
                                    <a href="/services/rng-admart"><span>Read more</span>  <i class="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="cardcontaner">
                            <div className="cardbox">
                                <div className="cardboximg">
                                    <div className="cardimgbox">
                                    <i class="zmdi zmdi-trending-up cardimgboxi"></i>
                                    </div>
                                </div>
                                <h3>Digital Marketing</h3>
                                <p>Digital marketing: Your pathway to reaching global audiences, driving online visibility, and engaging customers through targeted strategies...</p>
                                <div className="cardboxbutton">
                                    <a href="/services/Digital-Marketing"><span>Read more</span>  <i class="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="cardcontaner">
                            <div className="cardbox">
                                <div className="cardboximg">
                                    <div className="cardimgbox">
                                    <i class="zmdi zmdi-city-alt cardimgboxi"></i>
                                    </div>
                                </div>
                                <h3>Construction</h3>
                                <p>The art of transforming blueprints into tangible structures, blending innovation and craftsmanship. From towering skyscrapers to intricate architectural marvels...</p>
                                <div className="cardboxbutton">
                                    <a href="/services/Construction"><span>Read more</span> <i class="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    

                   

                  
                   
                    </div> 
                    

                </Swiper>



                 
            </div>

        </>
    )
}

export default Cardslider
