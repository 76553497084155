import React from 'react'
import Aboutsec1 from './about/aboutsec1/Aboutsec1'
import Team from './team/Team'
import { Helmet } from 'react-helmet'
import Team1 from './team1/Team1'

function TeamMembers() {
  return (
    <>

    <Helmet>
    <title>Collaborative Brilliance: Meet the Powerhouse Team of RNG's Interior, Construction, Digital Marketing,
    and PR Experts</title>
    <meta
      name="description"
      content="Unleashing talent: Meet RNG's dynamic team of experts in interior design, construction, digital
      marketing, and PR. Together, we create remarkable outcomes."
      
    />
    <link rel="canonical" href="/team" />

    </Helmet>


      <Aboutsec1 title=" Our Team" content="Behind the success of RNG Admart, Dishank Realtors and Social 2 Vocal lies a dynamic team of professionals driven by passion and expertise. With diverse backgrounds in interior design, construction, digital marketing, and PR, our team brings a wealth of knowledge and creativity to every project. Committed to delivering excellence, we collaborate seamlessly, combining our individual strengths to achieve outstanding results. Guided by a shared vision and a dedication to client satisfaction, the RNG team is the driving force behind our continuous growth and reputation for excellence." />
   
      <Team1/>
    </>
  )
}

export default TeamMembers
