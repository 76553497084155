import React from 'react'
import './Team1.css'
import img1 from '../img/team5.jpg'
import img2 from '../img/team2.jpg'
import img3 from '../img/team3.jpg'
import img4 from '../img/team4.jpg'
function Team1() {
    return (
        <>

            <div className="container">
                <div className="team1boxs">
                    <div className="team1box">
                        <div className="team1profile">

                            <img src={img2} alt="" />
                        </div>
                        <div className="team1content">
                        <h2>Shhankar</h2>
                        <h4>(Marketing Head)</h4>
                        </div>
                    </div>
                  
                    <div className="team1box">
                        <div className="team1profile">
                            <img src={img3} alt="" />
                        </div>
                        <div className="team1content">
                        <h2>Suruchi</h2>
                        <h4>(Operation & Finance head)</h4>
                        </div>
                    </div>
                  
                    <div className="team1box">
                        <div className="team1profile">

                            <img src={img4} alt="" />
                        </div>
                        <div className="team1content">
                        <h2>Anshika</h2>
                        <h4>(Business and Social Media Analyst)</h4>
                        </div>
                    </div>
                  
                    <div className="team1box">
                        <div className="team1profile">

                            <img src={img1} alt="Rahul frontend developer " />
                        </div>
                        <div className="team1content">
                        <h2>Rahul</h2>
                        <h4>(Frontend Developer)</h4>
                        </div>
                    </div>
                  
                 
                </div>


            </div>


        </>
    )
}

export default Team1
