import React from 'react'
import './Testimonial.css'


function Testimonial() {
    return (
        <>

            <div className="testimonialcontaner">



                <div className="testimonialboxs">

                    <div className="testimonialcards">

                        <div className="testimonialcard2" id='card1'>

                            <h5>Team Icon Infra</h5>

                            <h6><span><i class="zmdi zmdi-star"></i></span><span><i class="zmdi zmdi-star"></i></span><span><i class="zmdi zmdi-star"></i></span></h6>

                        </div>
                        <div className="testimonialcard1">
                            <h2> <span>“</span>As Icon Infra, a prominent construction company, I am honored to provide my heartfelt
                                testimony for RNG Admart, a remarkable PR company that has consistently demonstrated excellence in
                                both digital marketing and the construction industry. <br />
                                In an era where effective communication and strategic marketing are crucial for the success of any
                                business, RNG Admart has proven to be an invaluable partner for our organization. Their ability to
                                seamlessly blend their expertise in public relations with their deep understanding of the digital
                                landscape has significantly contributed to our growth and success. <br />
                                What sets RNG Admart apart is their versatility in handling both digital marketing and construction PR.
                                Their team possesses an in-depth understanding of the construction industry, allowing them to create
                                compelling content that resonates with our stakeholders and showcases our projects in the best light
                                possible. Whether it's writing engaging press releases, organizing impactful events, or crafting thought
                                leadership articles, RNG Admart has consistently exceeded our expectations. </h2>
                        </div>
                        <div className="testimonialcard2" id='card2'>

                            <h5>Team Icon Infra</h5>

                            <h6><span><i class="zmdi zmdi-star"></i></span><span><i class="zmdi zmdi-star"></i></span><span><i class="zmdi zmdi-star"></i></span></h6>

                        </div>

                    </div>
                    <div className="testimonialcards">
                        <div className="testimonialcard2">

                            <h5>Team Ecogram</h5>

                            <h6><span><i class="zmdi zmdi-star"></i></span><span><i class="zmdi zmdi-star"></i></span><span><i class="zmdi zmdi-star"></i></span></h6>

                        </div>
                        <div className="testimonialcard1 cardline">

                            <h2> <span>“</span>I am thrilled to provide my enthusiastic testimonial for RNG Admart, a complete PR company that
                                seamlessly operates in both the digital marketing and construction realms. As the Director of Ecograms,
                                I have had the pleasure of collaborating closely with RNG Admart, and their exceptional services have
                                significantly impacted our business. <br />
                                RNG Admart's unique ability to bridge the gap between digital marketing and construction is truly
                                remarkable. Their tailored strategies and deep industry knowledge have allowed us to effectively reach
                                our target audience and enhance our brand presence. <br />
                                The team at RNG Admart consistently goes above and beyond to deliver outstanding results. Their
                                attention to detail, proactive approach, and dedication to our success have surpassed our expectations.
                                Working with RNG Admart has been a game-changer for Ecograms. Their expertise, professionalism, and
                                commitment make them an invaluable PR partner. <br />
                                I wholeheartedly recommend RNG Admart to any company seeking a comprehensive PR solution that
                                seamlessly blends digital marketing and construction expertise. Their services will undoubtedly drive
                                your business to new heights. </h2>
                        </div>

                    </div>

                    <div className="testimonialcards">

                    <div className="testimonialcard2" id='card1'>

                    <h5>Horticulture of uttar pradesh</h5>

                    <h6><span><i class="zmdi zmdi-star"></i></span><span><i class="zmdi zmdi-star"></i></span><span><i class="zmdi zmdi-star"></i></span></h6>

                </div>
                        <div className="testimonialcard1">
                            <h2> <span>“</span>I am thrilled to share my enthusiastic testimonial for RNG Admart. As the Team of Horticulture Uttar
                                Pradesh, RNG Admart has been an invaluable PR partner. Their exceptional strategies and dedication
                                
                                have significantly contributed to our brand's success. I highly recommend RNG Admart to any company
                                seeking a reliable and results-driven PR company. </h2>
                        </div>
                        <div className="testimonialcard2" id='card2'>

                            <h5>Horticulture of uttar pradesh</h5>

                            <h6><span><i class="zmdi zmdi-star"></i></span><span><i class="zmdi zmdi-star"></i></span><span><i class="zmdi zmdi-star"></i></span></h6>

                        </div>

                    </div>
                    <div className="testimonialcards">
                        <div className="testimonialcard2 cardlast2">

                            <h5>Team Nishu welfair foundation</h5>

                            <h6><span><i class="zmdi zmdi-star"></i></span><span><i class="zmdi zmdi-star"></i></span><span><i class="zmdi zmdi-star"></i></span></h6>

                        </div>
                        <div className="testimonialcard1 cardlast2 cardline">

                            <h2> <span>“</span>We are immensely grateful for the partnership we have with RNG Admart. As an NGO, the support and
                                expertise they provide in public relations have been instrumental in expanding our reach and impact.
                                RNG Admart's commitment to our cause and their strategic approach have made them an invaluable PR
                                company for the Nishu Welfare Foundation.  </h2>
                        </div>

                    </div>



                </div>

            </div>


        </>
    )
}

export default Testimonial
