import React from 'react'

// import img1 from '../img/picontent.jpg'
import img1 from '../img/backend.jpg'
// import img2 from '../img/transparent21.png'
import img2 from '../img/dfgf.png'


import './Piccontent.css'
import { NavLink } from 'react-router-dom'

function Piccontent({ p, h2, button, sp }) {
  return (
    <>

      <div className="piccontentcontainer">

        <div className="piccontentboxs">

          <div className="piccontentbox">

            <img src={img1} alt="Rng Admart" />
            <div className="hoverimgeffect">
           <img src={img2} alt="Dishank Realtors" />
            </div>
          </div>
          <div className="piccontentbox">
            <h4><span>{sp}</span> {p}</h4>
            <h6>{h2}</h6>
            <div className="piccontentbutton1">
              <button> <a href="/about">{button}</a> </button>
            </div>

          </div>

        </div>

      </div>

    </>
  )
}

export default Piccontent
