import React from 'react'
import Aboutsec1 from './about/aboutsec1/Aboutsec1'
import Aboutsec2 from './about/aboutsec2/Aboutsec2'
import Aboutsec3 from './about/aboutsec3/Aboutsec3'
import { Helmet } from 'react-helmet'
import Slider from './slider/Slider'

function About() {
  return (
    <>

    <Helmet>
    <title> Unleashing Potential: RNG - Empowering Spaces, Transforming Brands</title>
    
    <meta
      name="description"
      content="RNG the force behind Dhishnk Real-Estate Company and S2V Digital Company,
      revolutionizes interior design, construction, digital marketing, and PR."
      
    />
    <link rel="canonical" href="/about" />

    </Helmet>

      <Aboutsec1 title ="About Us" content ="Welcome to RNG Admart, where the realms of construction and digital marketing converge to shape your vision
      into reality. We are not your ordinary company; we are the creators of distinctive spaces and the
      architects of online success. With a fusion of craftsmanship and cutting-edge marketing strategies, we
      embark on a journey to revolutionize your interior design aspirations and elevate your digital presence." />
      <Aboutsec2/>
      
      <Aboutsec3/>
    </>
  )
}

export default About
