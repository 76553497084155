import React from 'react'
import Aboutsec1 from './about/aboutsec1/Aboutsec1'
import Branch from './branches/Branch'

function Branch2() {

  let heading = "Social2Vocal - Empowering Your Digital Presence"
 
  let para1 = `There are several compelling reasons why we choose to become a digital marketing company. Here are
  a few key factors that often drive this decision:`
  let para2 = `Evolving Digital Landscape: With the rapid growth of the internet and digital technologies, businesses
  have recognized the need to establish a strong online presence. By becoming a digital marketing
  company, individuals can capitalize on this growing demand and help businesses navigate the ever-
  changing digital landscape.`
  let para3 = `Targeted Audience Reach: Digital marketing offers unprecedented opportunities to reach highly
  targeted audiences. As a digital marketing company, individuals can harness the power of social media;
  search engine optimization (SEO), content marketing, and other digital channels to help businesses
  effectively connect with their target customers, drive website traffic, and generate leads.`
  let para4 = `Measurable Results: Digital marketing provides the advantage of accurate tracking and measurement.
  By leveraging various tools and analytics, digital marketing companies can provide clients with detailed
  insights into the performance of their marketing campaigns. This data-driven approach enables
  businesses to make informed decisions and optimize their marketing strategies for better results.`
  let para5 = `Cost-Effectiveness: Compared to traditional marketing channels, digital marketing often offers a more
  cost-effective solution. By becoming a digital marketing company, individuals can help businesses
  maximize their marketing budgets by focusing on targeted campaigns, optimizing ad spends, and
  leveraging cost-efficient digital channels.`
  let para6 = `Constant Innovation and Learning: Digital marketing is a dynamic field that is constantly evolving. As a
  digital marketing company, individuals can embrace this ever-changing landscape and continuously
  learn and adapt to new technologies, trends, and strategies. This constant innovation ensures that
  businesses stay ahead of their competition and reach their marketing goals effectively.`
  let para7 = `Global Reach: The digital world knows no geographical boundaries. By becoming a digital marketing
  company, individuals can help businesses expand their reach beyond local markets and tap into a global
  audience. This opens up immense growth opportunities for both the digital marketing company and its
  clients.`
  let para8 = `Creativity and Flexibility: Digital marketing allows for creativity and flexibility in campaign execution.
  From crafting engaging content to designing visually appealing ads, individuals in a digital marketing
  company can unleash their creativity to capture the attention of target audiences and deliver impactful
  marketing messages.`
  let para9 = `Overall, becoming a digital marketing company provides individuals with an exciting career path in a
  fast-paced industry. It allows them to leverage digital channels, data-driven insights, and creative
  strategies to help businesses thrive in the digital era. By staying ahead of trends, providing measurable
  
  results, and fostering innovation, digital marketing companies can make a significant impact on their
  client's success.`

  let spam = ""

  return (
    <>


      <Aboutsec1 title=' Digital Marketing' content="In today's competitive business landscape, it is crucial to have a comprehensive solution that caters to multiple aspects of your enterprise. RNG offer just that, with two distinct branches: Social2Vocal for digital marketing and PR agency services, and Dishank Realtors for construction, real estate, and interior designing." />

      <Branch spam={spam} heading={heading}   para1 = {para1} para2 = {para2} para3 = {para3} para4 = {para4} para5 = {para5} para6 = {para6} para7 = {para7} para8 = {para8} para9 = {para9} />

    </>
  )
}

export default Branch2
