
import './App.css';
import Navbar from './components/navbar/Navbar';
import Sidebar from './components/mobilenavbar/Sidebar';
import Backdrop from './components/mobilenavbar/Backdrop';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import About from './components/About';
import OurServices from './components/OurServices';
import Projects from './components/Projects';
import Contact from './components/Contact';
import TeamMembers from './components/TeamMembers';
import History from './components/History';
import Footer from './components/footer/Footer';
import Home from './components/Home';
import Testimonials from './components/Testimonials';
import Branch1 from './components/Branch1';
import Branch2 from './components/Branch2';
import Branch3 from './components/Branch3';
import Services1 from './components/Services1';
import Services2 from './components/Services2';
import Aboutrng1 from './components/Aboutrng1';
import Aboutrng3 from './components/Aboutrng3';
import Privacy from './components/Privacy';

function App() {

const [sidebar, setsidebar] = useState(false);

const toggleSidebar = ()=>{
setsidebar((prev)=>!prev)

}



  return (
    <>
    
    <Navbar  openSidebar={toggleSidebar}/>

    <Sidebar sidebar={sidebar} closeback = {toggleSidebar} />
    <Backdrop sidebar={sidebar} closeback = {toggleSidebar} />


  <Routes>
    <Route path='/' element={<Home/>} > </Route>
    <Route path='/about' element={<About/>} > </Route>
    <Route path='/about/collaboration' element={<Aboutrng1/>} > </Route>
    <Route path='/about/planning' element={<Aboutrng3/>} > </Route>
    <Route path='/services' element={<OurServices/>} > </Route>    
    <Route path='/projects' element={<Projects/>} > </Route>
    <Route path='/contact' element={<Contact/>} > </Route>
    <Route path='/overview' element={<About/>} > </Route>
    <Route path='/teammember' element={<TeamMembers/>} > </Route>
    <Route path='/history' element={<History/>} > </Route>
    <Route path='/testimonials' element={<Testimonials/>} > </Route>
    <Route path='/services/Digital-Marketing' element={<Branch2/>} > </Route>
    <Route path='/services/Construction' element={<Branch1/>} > </Route>
    <Route path='/services/rng-admart' element={<Branch3/>} > </Route>
    <Route path='/Dishank-Realtors' element={<Services1/>} > </Route>
    <Route path='/Social2Vocal' element={<Services2/>} > </Route>
    <Route path='Privacy-Policy' element={<Privacy/>} > </Route>
   
   
 </Routes>

    <Footer/>

    </>
  );
}

export default App;
