import React from 'react'
import './Aboutsec1.css'
function Aboutsec1({title, content}) {
  return (
    <>
    <div className="aboutsec1container">
    <div className="aboutcard">
    <h3>{title}</h3>
    <p>{content}</p>
  
  
    </div>
    
    </div>
      
    </>
  )
}

export default Aboutsec1
