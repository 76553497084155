import React from 'react'
import Homemain from './homemain/Homemain'
import Crew from './crew/Crew'
import Piccontent from './piccontent/Piccontent'
import RuningChangeText from './runningchnagetext/RuningChangeText'
import Slider from './slider/Slider'
import { Helmet } from 'react-helmet'

function Home() {
  let h2 = "In the dynamic world of business, having a reliable and comprehensive consulting partner can make a significant difference in achieving success and staying ahead of the competition. RNG Admart is a pioneering company that offers a complete range of business consulting services under one roof, catering to the diverse needs of organizations across various industries."
  return (
    <>

    <Helmet>
    <title>RNG's Expertise in Interior, Construction and Digital Marketing | Rng Admart</title>
    <meta
      name="description"
      content="RNG the visionary mother company of Dhishnk Real-Estate Company and
      S2V Digital Company, creates stunning spaces through interior design, construction, digital marketing,
      and PR."
    />
    <link rel="canonical" href="/" />
  </Helmet>

      <Homemain />
      <Piccontent sp = 'RNG Admart'  p=' - Expertise and Experience at work ' h2={h2} button='Know More' />
      <Slider/>
      <RuningChangeText />
      
    </>
  )

}

export default Home
