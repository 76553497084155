import React from 'react'
import Aboutsec1 from './about/aboutsec1/Aboutsec1'
import Testimonial from './testimonials/Testimonial'
import { Helmet } from 'react-helmet'

function Testimonials() {
  return (
    <>

    <Helmet>
    <title>Building Success Stories: RNG's Satisfied Clients Speak Out on Interior, Construction, Digital Marketing,
    and PR Excellence</title>
    <meta
      name="description"
      content="Success stories unveiled: Hear from our satisfied clients about RNG's exceptional services in interior
      design, construction, digital marketing, and PR. Let their testimonials inspire you."
      
    />
    <link rel="canonical" href="/testimonials" />

    </Helmet>


    <Aboutsec1 title =" Client & Testimonials" content ="Proactively envisioned multimedia based expertise and cross-media growth strategies. Seamlessly visualize quality intellectual capital without superior collaboration and idea-sharing." />
    <Testimonial/>
    </>
  )
}

export default Testimonials
