import React, { useState } from 'react'
import './Footer.css'
import Privacy from '../Privacy'
import Refund from '../Refund'

function Footer() {



    const [show, setshow] = useState(false)
    const [showrefund, setshowrefund] = useState(false)

    const showterm = () => {
        setshow(true);
    }

    const showRefund =()=>{
        setshowrefund(true)
    }
    return (
        <>

        <div className={ show ? "showmodelfooter open" : "showmodelfooter"}>
        <div className="termpolicycontenter">
            <div className="termpolicybox">
                <Privacy/>
            </div>
        </div>
        <div className="closebutton">
        <span onClick={() => setshow(false)}><i class="zmdi zmdi-close"></i></span>
        </div>
        </div>

        <div className={ showrefund ? "showmodelfooter open" : "showmodelfooter"}>
        <div className="termpolicycontenter">
            <div className="termpolicybox">
                <Refund/>
            </div>
        </div>
        <div className="closebutton">
        <span onClick={() => setshowrefund(false)}><i class="zmdi zmdi-close"></i></span>
        </div>
        </div>


            <div className="footercontainer">

                <div className="footerboxs">
                    <div className="footerbox">
                        <div className="footerlogo">
                            <img src="/" alt="" />
                        </div>
                        <div className="footercontent">
                            <h4>At RNG Admart, our vision is to be a leading force in transforming spaces, brands, and businesses. We
                                strive to create inspiring and functional designs, establish impactful communication strategies, drive
                                digital growth, and construct visionary projects. With our multidimensional expertise, we aim to
                                empower our clients, surpass their expectations, and shape a better future through innovation,
                                collaboration, and excellence.</h4>
                        </div>
                    </div>
                    <div className="footerbox">
                        <h3>Navigation</h3>
                        <div className="footerlinks">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/about">About</a></li>
                                
                                <li><a href="/services">Services</a></li>
                                <li><a href="/projects">Gallery</a></li>
                                <li><a href="/contact">Contact</a></li>

                            </ul>
                        </div>
                    </div>
                    <div className="footerbox">
                        <h3>Contact</h3>
                        <div className="footersocillinks">
                            <a target='_blank' href="https://www.facebook.com/profile.php?id=100068313607481&mibextid=ZbWKwL"> <i class="fa-brands fa-square-facebook ilogo"></i> </a>
                            <a href=""><i class="fa-brands fa-square-instagram ilogo"></i></a>
                            <a href=""> <i class="fa-brands fa-square-twitter ilogo"></i></a>

                        </div>
                    </div>
                </div>

                <hr />

               
                    <div className="footerconditons">
                        <span onClick={showterm}>  Privacy Policy Terms and Conditions</span>
                        <span onClick={showterm}> <a href="#"></a> </span>
                        <span onClick={showRefund}> Refund Policy </span>
    
                    </div>

            </div>

        </>
    )
}

export default Footer
