import React from 'react'
import Aboutsec1 from './about/aboutsec1/Aboutsec1'
import Branch from './branches/Branch'
import Branch4 from './branches/Branch4'
import logo from './img/Dishank logo.jpg'

import branchimg1 from './img/branchimg/DH2 logo.jpg'
import branchimg2 from './img/branchimg/fenny club and resort.jpg'
import branchimg3 from './img/branchimg/logo.png'
import branchimg4 from './img/branchimg/revised logo.jpg'
import branchimg5 from './img/branchimg/vj group.png'
import { Helmet } from 'react-helmet'

function Services1() {

  let heading = " - Building Dreams into Reality"

  let para1 = `When it comes to construction, real estate, and interior designing, Dishank Realtors, another branch of RNG, stands as a trusted partner. With a wealth of expertise and experience in the industry, Dishank Realtors offers a comprehensive range of services to transform your vision into reality. Whether it's constructing commercial properties, developing residential projects, or creating captivating interior designs, Dishank Realtors combines innovation, quality craftsmanship, and attention to detail to deliver exceptional outcomes. With a customer-centric approach, Dishank Realtors ensures that each project is executed with utmost professionalism and meets the highest standards of excellence.`

  let para2 = `RNG not only provide the precision and randomness required in mathematical and computer science applications but have also diversified into the realm of business solutions. Through its branches, Social2Vocal and Dishank Realtors, RNG offers a complete package for digital marketing, PR services, construction, real estate, and interior designing. Embrace the power of RNG's expertise and watch your business thrive in the digital and physical worlds.`

  let spam = "Dishank Realtors"
  let facebooklink  = 'https://www.facebook.com/profile.php?id=100092748782844&mibextid=ZbWKwL'
  let instalink = 'https://www.instagram.com/dishankrealtors/?igshid=Y2IzZGU1MTFhOQ%3D%3D'
  
let projectcontent = 'At Dishank Realtors, we take immense pride in our commitment to excellence, innovation, and the pursuit of building dreams into reality. Over the years, we have successfully completed a diverse range of projects, leaving a mark of craftsmanship and quality in the construction industry. Our projects showcase our passion for creating spaces that stand the test of time, leaving a lasting impression on the landscapes we shape.'

  return (
    <>

    <Helmet>
    <title> Dishank Realtors Building Dreams into Reality </title>
    
    <meta
      name="description"
      content="At Dishank Realtors, we take immense pride in our commitment to excellence, innovation, and the pursuit of building dreams into reality."
      
    />
    <link rel="canonical" href="/Dishank-Realtors" />

    </Helmet>


      <Aboutsec1 title='Branches' content="In today's competitive business landscape, it is crucial to have a comprehensive solution that caters to multiple aspects of your enterprise. RNG offer just that, with two distinct branches: Social2Vocal for digital marketing and PR  services, and Dishank Realtors for construction, real estate, and interior designing." />

      <Branch4  projectcontent = {projectcontent}  branchimg1 = {branchimg1} branchimg2 = {branchimg2} branchimg3 = {branchimg3} branchimg4 = {branchimg4} branchimg5 = {branchimg5}   logo = {logo} spam={spam} heading={heading} para1={para1} para2={para2} facebooklink = {facebooklink} instalink={instalink}/>
      


    </>
  )
}

export default Services1
