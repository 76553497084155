import React from 'react'
import Aboutsec1 from './about/aboutsec1/Aboutsec1'
import Team from './team/Team'
import Project from './project/Project'
import img1 from './img/projectsimg/1-Interiors Design1.jpeg'
import img2 from './img/projectsimg/2-Interiors Design2.jpeg'
import img3 from './img/projectsimg/3-Interiors Design3.jpeg'
import img4 from './img/projectsimg/4-Interiors Design4.jpeg'
import img5 from './img/projectsimg/5-Interiors Design7.jpeg'
import img6 from './img/projectsimg/6-project25.jpg'
import img7 from './img/projectsimg/7-project24.jpg'
import img8 from './img/projectsimg/8-project23.jpeg'
import img9 from './img/projectsimg/9-project21.jpg'
import img10 from './img/projectsimg/10-Interiors Design5.jpeg'
import img11 from './img/projectsimg/11-Interiors Design6.jpeg'
import img12 from './img/projectsimg/12-Interiors Design8.jpeg'
import img13 from './img/projectsimg/13-project22.jpeg'
import { Helmet } from 'react-helmet'


function Projects() {

  let data =[
    {
      id:1,
      imgSrc:img1,
    },
    {
      id:2,
      imgSrc:img2,
    },
    
    {
      id:3,
      imgSrc:img3,
    },
    
    {
      id:4,
      imgSrc:img4,
    },
    
    {
      id:5,
      imgSrc:img5,
    },
    {
      id:6,
      imgSrc:img6,
    },
    {
      id:7,
      imgSrc:img7,
    },
    {
      id:8,
      imgSrc:img8,
    },
    {
      id:9,
      imgSrc:img9,
    },
    {
      id:10,
      imgSrc:img10,
    },
    {
      id:11,
      imgSrc:img11,
    },
    {
      id:12,
      imgSrc:img12,
    },
    {
      id:13,
      imgSrc:img13,
    },
    
   
   
  ]

  return (
    <>

    <Helmet>
    <title>RNG Admart in Lucknow</title>
    
    <meta
      name="description"
      content="Proactively envisioned multimedia based expertise and cross-media growth strategies. Seamlessly visualize quality intellectual capital without superior collaboration and idea-sharing."
      
    />
    <link rel="canonical" href="/projects" />

    </Helmet>



      <Aboutsec1 title = "Gallery" content = "Proactively envisioned multimedia based expertise and cross-media growth strategies. Seamlessly visualize quality intellectual capital without superior collaboration and idea-sharing.         " />
      <Project data = {data}  />
    </>
  )
}

export default Projects
