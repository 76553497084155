import React from 'react'
import './Mobilebar.css'
import { NavLink } from 'react-router-dom'
function Sidebar({sidebar, closeback}) {
  return (
    <>

    <div className={sidebar?"sidebar sidebaropen":"sidebar"}>
    <div className="profile">
     

    </div>
    <div className="homelinks"  >
    <li onClick={closeback}  className="nav-item ">
    <NavLink className="nav-link " to="/" >
      Home
    </NavLink>
  </li>
    <li  className="nav-item  aboutlinkssidebar">
    <NavLink className="nav-link " href="/about" >About Us  <i class="zmdi zmdi-chevron-down"></i></NavLink>
    <div className="sideaboutlinks">
    <ul>
    <li onClick={closeback} ><NavLink to="/overview">OverView</NavLink></li>
    <li onClick={closeback} ><NavLink to="/history">History</NavLink></li>
    <li onClick={closeback} ><NavLink to="/teammember">Team Members</NavLink></li>
    <li onClick={closeback} ><NavLink to="/testimonials">Clients & Testimonials</NavLink></li>
    </ul>
    </div>
  </li>
    <li onClick={closeback}  className="nav-item ">
    <NavLink className="nav-link " to="/services" >
    Our Services
    </NavLink>
  </li>
    <li   className="nav-item aboutlinkssidebar ">
    <NavLink className="nav-link " to="Projects" > Gallery </NavLink>
   
  </li>
    <li  className="nav-item aboutlinkssidebar ">
    <NavLink className="nav-link " to="#" > Branches <i class="zmdi zmdi-chevron-down"></i></NavLink>
 <div className="sideaboutlinks">
    <ul>
    <li onClick={closeback} ><NavLink to="/Dishank-Realtors">Dishank Realtors</NavLink></li>
    <li onClick={closeback} ><NavLink to="/Social2Vocal">Social2Vocal</NavLink></li>
   </ul>
    </div>
  </li>
    <li onClick={closeback}  className="nav-item ">
    <NavLink className="nav-link " to="/contact" >
    Contact Us
    </NavLink>
  </li>

    </div>

   
  </div>


      
    </>
  )
}

export default Sidebar
